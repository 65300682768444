/* Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 2rem;
  margin-bottom: 4rem;
} 

.login-form {
  background: #fff;
  padding: 50px 30px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 90%;
  max-width: 400px;
}

.svg-logo {
  display: block;
  margin: 0 auto 20px;
  width: 150px; /* Adjust the size of the logo */
  height: auto;
}


.login-form h1 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.social-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  border-top-right-radius: 0px;
  font-size: 16px;
  color: black;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.social-login-button:hover {
  transform: scale(1.02);
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  border-top-right-radius: 0px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  margin-bottom: 20px;
  font-weight: 700;
  background-color: #0C831F;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.login-button:hover {
  transform: scale(1.02);
}


.google-login-button-icon-gap {
  margin-left: 10px;
}

.or-divider {
  text-align: center;
  margin: 15px 0;
  font-size: 14px;
  color: black;
}

.login-form form {
  display: flex;
  flex-direction: column;
}

.login-form input {
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
}

.terms-consitions-label{
  margin-top: -15px;
}

/* .login-form button {
  padding: 12px;
  border: none;
  border-radius: 6px;
  background: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.login-form button:hover {
  background: #db4437;
  transform: scale(1.02);
} */

.forgot-password-link {
  display: block;
  margin-bottom: 10px;
  text-align: right;
  font-size: 14px;
  color: black;
  font-weight: 500;
  text-decoration: line-through;
}

.forgot-password-link:hover {
  text-decoration: underline;
  color: white;
  background-color: black;
  padding: 5px 10px;
}

.forgot-password-on-hover-event{
  display: block;
  margin-bottom: 10px;
  text-align: right;
  font-size: 14px;
  color: black;
  font-weight: 500;
  text-decoration: underline;
}

.terms-and-conditions {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.terms-and-conditions input[type="checkbox"] {
  margin-right: 10px;
}

.terms-and-conditions label {
  font-size: 14px;
}

.terms-and-conditions label a {
  color: black;
  font-weight: 500;
}

.terms-and-conditions label a:hover {
  text-decoration: underline;
}

.signup-link {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

.signup-link a {
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.signup-link a:hover {
  text-decoration: underline;
  color: white;
  background-color: black;
  padding: 5px 10px;
}
