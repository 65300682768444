.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    z-index: var(--z-fixed);
    border-bottom: 1px solid #ddd;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin: 1rem;
}

.nav__track-order {
    display: flex;
    align-items: center;
    background-color: #ff4500; /* Change to any color you like */
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav__track-order:hover {
    background-color: #0C831F; /* Slightly lighter shade on hover */
    transform: translateY(-2px); /* Lift effect on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav__track-order:active {
    background-color: #0C831F; /* Darker shade on click */
    transform: translateY(0); /* Reset lift effect */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.nav__logo {
    color: var(--first-color);
    transition: color 0.4s;
    font-size: var(--h2-font-size);
    font-family: var(--second-font);
}

.nav__logo img {
    width: 200px; /* Adjust as needed */
    height: auto;
    padding: 10px;
}


.nav__toggle,
.nav__close {
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.nav__cta__text {
    color: white;
    font-weight: 500;
    transition: color 0.4s;
}

.nav__cta {
    background-color: #0C831F;
    color: white;
    border: none;
    border-radius: 4px;
    border-top-right-radius: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0.75rem 1.5rem;
}

.nav__cta:hover{
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
}

.nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.nav__link {
    color: var(--title-color);
    font-weight: 500;
    transition: color 0.4s;
}

.nav__link:hover {
    color: var(--first-color);
}

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.show-menu {
    right: 0;
}

.nav__list__web {
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin-right: 2rem;
}

/* Media Queries */
@media screen and (max-width: 1150px) {
    .nav__menu {
        position: fixed;
        top: 0;
        right: -100%;
        background-color: hsla(0, 0%, 6%, 0.2);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        width: 70%;
        height: 100%;
        padding: 6rem 3rem 0;
        transition: right 0.4s;
    }

    .nav__list {
        flex-direction: column;
        row-gap: 2.5rem;
    }

    .show-menu {
        right: 0;
    }
}

@media screen and (max-width: 300px) {
    .nav__menu {
        padding: 4rem 1rem 2rem;
    }

    .nav__list {
        row-gap: 1rem;
    }

    .nav__link,
    .nav__cta {
        font-size: var(--smaller-font-size);
    }

    .nav__cta {
        padding: 8px 16px;
        font-size: 12px;
        margin-top: 1rem;
        width: auto;
        white-space: nowrap;
    }
}

@media screen and (min-width: 600px) {
    .nav {
        height: calc(var(--header-height) + 0.1rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__list__web {
        display: flex;
        flex-direction: row;
        column-gap: 2.5rem;
    }
}
