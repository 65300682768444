.Main-combined-container {
    margin-top: 6rem;
    /* border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; */
    background-color: #F7FFF9;
    padding: 4rem 0;
}

@media (min-width: 1024px) {
    .Main-combined-container {
        margin-top: 4rem;
    }
}

@media (max-width: 767px) {
    .Main-combined-container {
        margin-top: none; 
        padding: 0px;
    }
}
