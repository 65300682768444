.how-it-works {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* background-color: #f9f9f9; */
}

.step {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 0;
    width: 100%;
    padding: 0 8rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* .step:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
} */

.step.reverse {
    flex-direction: row-reverse;
}

.speech-bubble {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    padding: 30px 40px;
    position: relative;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    animation: bubble-animation 2s infinite;
}

.normal-bubble {
    margin-right: 8rem;
}

.reverse-bubble {
    margin-left: 8rem;
}

.icon-container {
    font-size: 4rem;
    color: #1DA1F2;
    margin: 0 2rem;
    padding: 20px;
    background-color: #e0f7fa;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.how-it-works-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    margin-top: 6rem;
}

.how-it-works-heading {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    padding: 0 22rem;
}

.how-it-works-heading::after {
    content: '';
    width: 80px;
    height: 5px;
    background-color: #1DA1F2;
    display: block;
    margin: 20px auto 0;
    border-radius: 2px;
}

@keyframes bubble-animation {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

@media (max-width: 767px) {
    .step,
    .step.reverse {
        flex-direction: column;
        text-align: center;
        padding: 0;
    }

    .normal-bubble,
    .reverse-bubble {
        margin: 0;
    }

    .how-it-works-heading {
        font-size: 2.2em;
        padding: 0;
        margin-top: 0px;
    }

    .icon-container{
        margin: 2rem 0.5rem;
    }
}
