.payment-failure-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.payment-failure-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #f4f7f6; */
  text-align: center;
  padding: 70px 30px;
  margin: 10px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.payment-failure-icon {
  font-size: 4rem;
  color: #DC3545;
  margin-bottom: 1rem;
}

.payment-failure-heading {
  font-size: 2rem;
  color: black;
  margin-bottom: 0.5rem;
}

.payment-failure-message {
  font-size: 1rem;
  color: black;
  margin-bottom: 2rem;
}

.payment-failure-button {
  background-color: rgba(12, 131, 31, 0.05);
  border: 1.5px solid #DC3545;
  border-radius: 5px;
  margin-top: auto; 
  color: #DC3545;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 3rem;
  transition: background-color 0.3s ease;
  width: 80%; 
  box-sizing: border-box; 
}

.payment-failure-button:hover {
  background-color: #DC3545;
  color: white;
  transform: scale(1.01);
}