.footer {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #ddd;
}

.footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__logo img {
    max-height: 50px; /* Adjust the size as needed */
    margin-top: 10px;
}

.footer__social {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.footer__social__icon {
    margin: 0 10px;
    color: #333;
    font-size: 24px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.footer__social__icon:hover {
    transform: translateY(-5px); /* Move the icon up by 5px */
}

.footer__social__icon[href="https://www.facebook.com/profile.php?id=61566496836252"] {
    color: #3b5998; /* Facebook Blue */
}

.footer__social__icon[href="https://www.instagram.com/bigdoonmedia?igsh=MWI4cnA0cjNvcWNhNw=="] {
    color: #e4405f; /* Instagram Pink */
}

.footer__social__icon[href="https://x.com/bigdoonmedia"] {
    color: #1da1f2; /* X (Twitter) Blue */
}

.footer__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
}

.footer__links a {
    margin: 5px 10px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
}

.footer__nav a{
    color: white;
    background-color: black;
    padding: 5px 10px;
    font-weight: 500;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.footer__links a:hover {
    text-decoration: underline;
    text-decoration: none;
}

.footer__links--legal {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.footer__links--legal p {
    font-size: 16px; /* Adjust as needed */
    font-weight: bold;
    margin: 10px 0;
    color: white;
    background-color: black;
    padding: 5px 10px;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
}

.footer__text {
    font-size: 14px;
    color: black;
    margin-top: 10px;
    font-weight: 500;
}

.footer__text__made__with__love {
    font-size: 14px;
    color: #666;
}

.heart {
    display: inline-block;
    font-size: 20px;
    animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}
