/* Pricing.css */

/* Block Element Modifier for Pricing component */
.pricing-container {
  padding: 2rem;
  margin-top: 2rem;
  text-align: center;
  color: black;
  margin-bottom: 4rem;
}

.pricing-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
  align-items: center;
}

.pricing-header p {
  width: 90%;
  align-self: center;
  line-height: 1.5;
  color: black;
  margin: 5px 0 0;
}

.pricing-container__title { 
  font-size: 2.5em;
  color: black;
  margin: 0;
  line-height: 1.5em;
  margin-bottom: 0.8rem;
}

.text-perfect-plan-background{
  color: white;
  background-color: black;
  padding: 5px 10px;
}

.pricing-container__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin-top: 3rem;
  justify-content: center;
}

.pricing-card {
  background-color: #F7FFF9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 100%;
  max-width: 300px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease;
  display: flex; /* Added */
  flex-direction: column; /* Added */
  justify-content: space-between; /* Added */
}

.pricing-card__title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.pricing-card__price {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.pricing-card__list {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1em;
  font-family: 800;
}

.pricing-card__list-item {
  margin: 0.5rem 0;
}

.plan-price-text{
  text-decoration: underline;
  text-decoration-color: #0C831F;
}

.pricing-card__button {
  background-color: rgba(12, 131, 31, 0.05);
  border: 1.5px solid #0C831F;
  border-radius: 5px;
  margin-top: auto; 
  color: #0C831F;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 3rem;
  transition: background-color 0.3s ease;
  width: 100%; 
  box-sizing: border-box; 
}

.pricing-card__button:hover {
  background-color: #0C831F;
  color: white;
  transform: scale(1.01);
}

.pricing-card--featured {
  background-color: #F7F4D1;
  transform: scale(1.05);
}

.pricing-card__discount-label {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #0C831F;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 5px;
  transform: rotate(10deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Media queries */
@media (max-width: 768px) {
  .pricing-container__grid {
    flex-direction: column;
    align-items: center;
  }
}
