/* Import the custom font */
/* @font-face {
    font-family: 'Avangers';
    src: url('../../../fonts/Adumu.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */

/* Typing Effect Styles */
.typing-effect {
    display: inline-block;
    /* font-family: 'Avangers', sans-serif; */
    /* color: black;  */
    font-weight: bold;
    font-size: 4rem;
    /* padding: 20px; */
    white-space: nowrap; /* Ensure text does not wrap */
    /* letter-spacing: 0.1em; */
}

.cursor {
    display: inline-block;
    margin-left: 15px;
    font-family: 'Fortnite', sans-serif; /* Apply the custom font */
    color: black; /* Adjust cursor color */
    font-weight: bold;
    animation: blink 0.75s step-end infinite;
}

.cursor.hidden {
    visibility: hidden;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    75% { opacity: 0.9; }
    100% { opacity: 1; }
}
/* Mobile Styles */
@media (max-width: 768px) {
    .typing-effect {
        font-size: 2rem; /* Adjust font size for mobile */
        padding: 0px; /* Adjust padding for mobile */
    }

    .cursor {
        margin-left: 10px; /* Adjust cursor margin for mobile */
    }
}
