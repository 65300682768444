.track-order-container {
  display: flex;
  flex-direction: row; /* Arrange children in a row */
  gap: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  /* height: 100vh; 
  overflow: visible;  */
}

/* Styling for left-side content */
.track-order-left-side {
  flex: 1; /* Take available space */
  max-height: 100vh; /* Limit height to viewport height */
  border-right: 1px solid #ddd; /* Optional border for separation */
  padding-right: 10px;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Styling for user info and menu */
.track-order-user-info {
  margin-bottom: 20px;
}

.track-order-menu {
  margin-top: 20px;
}

.track-order-menu-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.track-order-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.track-order-menu-item {
  display: block;
  text-decoration: none;
  color: #333333; /* Link color */
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.track-order-menu-item:hover {
  background-color: #0C831F; /* Background color on hover */
  color: white; /* Link hover color */
}

.track-order-menu-item.active {
  background-color: #0C831F; /* Active tab color */
  color: white;
  /* margin-top: 40px; */
}

.track-order-user-details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.track-order-user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.track-order-user-initial {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #0C831F; /* Background color for initials */
  color: white;
  font-size: 36px;
  margin-right: 20px;
  text-transform: uppercase;
}

.track-order-user-text h2 {
  margin: 0;
  font-size: 24px;
}

.track-order-user-text p {
  margin: 5px 0;
  font-size: 16px;
}

.track-order-logout-btn { 
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.track-order-logout-btn:hover {
  background-color: #c0392b;
}

/* Styling for right-side content */
.track-order-right-side {
  flex: 2; /* Take twice the space of the left side */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 80vh;
  max-height: 100vh; /* Limit height to viewport height */
  padding-left: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .track-order-container {
    flex-direction: column; /* Stack left and right sides on top of each other */
    /* overflow: auto;  */
  }

  .track-order-left-side {
    flex: none; /* Prevent shrinking on mobile */
    max-height: none; /* Allow it to expand naturally */
    overflow: visible; /* Make sure content is fully visible */
    border-right: none; /* Remove border */
    padding: 10px; /* Adjust padding for mobile */
  }

  .track-order-right-side {
    flex: none;
    overflow-y: hidden;
    height: fit-content;
    max-height: 100%;
  }
}

/* track order */
/* Styling for the select-order container */
.select-order {
  display: flex;
  flex-direction: row; /* Arrange dropdowns in a row on larger screens */
  gap: 20px; /* Space between dropdowns */
  padding: 20px;
}

/* Common styling for dropdowns */
.order-dropdown, .street-dropdown {
  flex: 1; /* Ensure both dropdowns take equal space */
}

/* Styling for the dropdown labels */
.order-dropdown label, .street-dropdown label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Styling for the dropdown select elements */
.order-dropdown select, .street-dropdown select {
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #F5F4EF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}


/* Styling for dropdown focus */
.order-dropdown select:focus, .street-dropdown select:focus {
  border-color: #0C831F; /* Change border color on focus */
  box-shadow: 0 2px 6px rgba(255, 94, 91, 0.3); /* Enhance shadow on focus */
  outline: none; /* Remove default outline */
}

/* Responsive design for dropdowns */
@media (max-width: 768px) {
  .select-order {
    flex-direction: column; /* Stack dropdowns vertically on smaller screens */
    gap: 15px; /* Space between dropdowns */
    padding: 10px; /* Adjust padding for mobile */
  }

  .order-dropdown select, .street-dropdown select {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}



/* Order details */

.track-order-details{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* Individual Order Card */
.track-order-card {
  background-color: #F5F4EF;
  width: 95%;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: box-shadow 0.3s ease;
}

/* Order Heading */
.track-order-card h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

/* Order Details */
.track-order-card p {
  font-size: 1rem;
  margin: 5px 0;
  color: #555;
}

/* Link to View Map */
.track-order-card a {
  display: inline-block;
  margin-top: 10px;
  font-size: 0.875rem;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.track-order-card a:hover {
  color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .track-order-details {
    padding: 15px;
  }

  .track-order-card {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .track-order-card h3 {
    font-size: 1rem;
  }

  .track-order-card p {
    font-size: 0.875rem;
  }

  .track-order-card a {
    font-size: 0.75rem;
  }
}

/* Events css */
.order-events {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-direction: column;
  gap: 15px;
}

.order-event {
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 90%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.order-event-photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
  border: 2px solid #ddd;
}

.order-event-details {
  flex-grow: 1;
}

.order-event p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.order-event p b {
  color: #555;
}

.order-event p + p {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .order-event {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-event-photo {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

/* Container for the template details page */
.template-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Styling for the template selector dropdown */
.template-selector {
  margin-bottom: 20px;
}

.template-selector label {
  margin-right: 10px;
  font-weight: bold;
}

.template-selector select {
  padding: 5px;
  font-size: 16px;
}

/* Styling for the selected template details */
.template-details {
  text-align: center;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #F7FFF9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logo image styling */
.template-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  /* border-radius: 50%; */
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Styling for the details table */
.template-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.template-details-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.template-details-table tr:last-child td {
  border-bottom: none;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .template-selector select {
    width: 100%;
  }

  .template-details {
    width: 90%;
  }
}






