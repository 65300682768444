
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --header-height: 3.5rem;
  --first-color: #000000;
  --title-color: #4B4B4B;
  --text-color: #333333;
  --body-color: #FFFFFF;
  --body-font:  "Roboto", sans-serif;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

p{
  font-size: 22px;
}

#kjsjsoircu3g1722721332021 {
  display: none !important;
}