.about-us-container {
  font-family: Arial, sans-serif;
  color: black;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  margin-top: 2rem;
}

.about-us-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* margin-bottom: 40px; */
  align-items: center;
}

.about-us-header h1 {
  font-size: 2.5em;
  color: black;
  margin: 0;
  line-height: 1.5em;
  margin-bottom: 0.8rem;
}

.our-vision-heading{
  font-size: 2em;
  color: black;
  margin: 0;
  margin-bottom: 0.8rem;
}

.text-logo-black-background{
  background-color: black;
  color: white;
  padding: 10px;
}

.quote-container {
  width: 90%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
}

.quote {
  font-size: 1.5em;
  color: black; /* Text color */
  background-color: rgba(128, 128, 128, 0.1); /* Background color */
  line-height: 2;
  font-style: italic;
  border-left: 4px solid #0C831F;
  padding: 20px;
  margin: 0;
  position: relative;
  border-radius: 8px; /* Optional: Adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds subtle shadow */
}

.quote-highlight{
  background-color: white;
  color: black;
  font-weight: bold;
  padding: 10px;
}

.quote::before {
  content: open-quote;
  font-size: 3em;
  color: black;
  position: absolute;
  left: -38px; /* Adjust to fit well with the new padding */
  top: -20px;
}

.quote::after {
  content: close-quote;
  font-size: 3em;
  color: black;
  position: absolute;
  right: -25px; /* Adjust to fit well with the new padding */
  bottom: -50px;
}


.about-us-container-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 100vh; */
  margin-top: 50px;
  /* overflow-y: auto; */
  box-sizing: border-box;
}

.about-us-column {
  flex: 1;
  border-radius: 8px;
  padding: 20px;
  /* overflow-y: auto; */
  /* max-height: 100%; */
}

.left-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.right-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.middle-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.middle-column .svg-image {
  width: 100%;
  height: auto; 
  display: block;
  margin-top: 20px;
}


.left-column .svg-image {
  width: 100%;
  height: auto; 
  display: block;
  margin-top: 20px;
}

.right-column .svg-image {
  width: 100%;
  height: auto; 
  display: block;
  margin-top: 20px;
}

.our-vision-heading{
  margin-top: 1rem;
  background-color: black;
  color: white;
  padding: 10px;
  transform: rotate(-5deg); 
  display: inline-block;
}

.about-us-heading{
  margin-top: 1rem;
  background-color: black;
  color: white;
  padding: 10px;
  /* transform: rotate(-5deg);  */
  font-size: 40px;
  display: inline-block;
}

.our-vision-desc{
  margin-top: 15px;
  text-align: justify;
  font-size: 22px;
  line-height: 1.5;

}

.about-us-contact-div{
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  align-items: center;
  margin-top: 3rem;
}

.about-us-contact-div-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background: linear-gradient(135deg, green 0%, black 100%);
  height: 250px;
  width: 100%;
  border-radius: 15px;
}

.about-us-contact-div-content P{
  font-size: 28px; 
  font-weight: bold;
  color: white;
}

.about-us-contact-button{
  margin-top: 2rem;
  padding: 15px 20px;
  width: 60%;
  font-size: 28px;
  font-weight: bold;
  border-radius: 10px;
}
.about-us-contact-button:hover{
  color: white;
  background-color: #0C831F;
  padding: 16px 22px;
  border: #0C831F;

}

@media (max-width: 768px) {
  .about-us-container-body {
    flex-direction: column;
    gap: 10px;
  }

  .about-us-column:nth-child(1) {
    order: 2;
  }

  .about-us-column:nth-child(2) {
    order: 1;
  }

  .about-us-column:nth-child(3) {
    order: 3;
  }
}
