/* Base Styles */
/* @font-face {
    font-family: 'Fortnite';
    src: url('../../../fonts/Fortnite.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */

.top-heading-container {
    width: 100%;
    height: 80vh;
    /* padding-top: 6rem; */
    /* padding-bottom: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 6.5rem;
    /* border-bottom: 3px solid white; */
}

.top-heading-container h1 {
    width: 65%;
        padding-top: 6rem;
    font-size: 4rem;
    line-height: 6rem;
}

.top-heading-container p {
    width: 55%;
    font-size: 1.2rem;
    margin-top: 1rem;
    line-height: 2rem;
    font-weight: 500;
    color: #333333;
}

.highlight-text {
    color: #0C831F;
}

.hilighted-dot {
    margin-left: -10px;
    color: #000000;
}

/* Button */
.btn-container {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-top: 1.2rem;
    font-weight: 500;
}

.btn {
    line-height: 50px;
    height: 50px;
    text-align: center;
    width: 250px;
    cursor: pointer;
}

.btn-one {
    color: #0C831F;
    transition: all 0.3s;
    position: relative;
}

.btn-one span {
    transition: all 0.3s;
}

.btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #0C831F;
    border-bottom-color: #0C831F;
    transform: scale(0.1, 1);
}

.btn-one:hover span {
    letter-spacing: 2px;
}

.btn-one:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}

.btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
}

.btn-one:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .top-heading-container {
        padding-top: 6rem;
        padding-bottom: 6rem;
        padding-left: 2rem;
    }

    .top-heading-container h1 {
        font-size: 3.5rem;
        line-height: 4.5rem;
    }

    .highlight-text {
        font-size: 3.5rem;
    }

    .top-heading-container p {
        font-size: 1.1rem;
        line-height: 1.8rem;
    }

    .btn-container {
        width: 60%;
    }

    .btn {
        width: 200px;
        height: 45px;
        line-height: 45px;
    }
}

@media (max-width: 900px) {
    .top-heading-container {
        padding-top: 4rem;
        padding-bottom: 4rem;
        padding-left: 1.5rem;
    }

    .top-heading-container h1 {
        width: 90%;
        font-size: 2rem;
        line-height: 4rem;
    }

    .highlight-text {
        font-size: 2rem;
    }

    .top-heading-container p {
        width: 90%;
        font-size: 1rem;
        line-height: 1.6rem;
    }

    .highlight-text {
        font-size: 2rem;
    }

    .hilighted-dot {
        margin-left: -5px; /* Adjust as needed */
    }

    .btn-container {
        width: 80%;
    }

    .btn {
        width: 180px;
        height: 40px;
        line-height: 40px;
    }
}

@media (max-width: 600px) {
    .top-heading-container {
        padding-top: 6rem;
        padding-bottom: 3rem;
        padding-left: 2rem;
        padding-right: 1rem;
        text-align: left; 
    }

    .top-heading-container h1 {
        width: 90%;
        font-size: 2rem;
        line-height: 2.8rem;
    }

    .highlight-text {
        font-size: 2.5rem;
    }

    .top-heading-container p {
        margin-top: 1rem;
        width: 90%;
        font-size: 1.2rem;
        line-height: 1.8rem;
    }

    .btn-container {
        width: 100%;
    }

    .btn {
        width: 150px;
        height: 35px;
        line-height: 35px;
    }
}
