
  .editor-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    margin: 20px;
  }
  
  .editor-heading {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .editor-description {
    font-size: 1.2em;
    color: #666;
    line-height: 1.6;
    text-align: center;
  }
  
  @media (max-width: 600px) {
    .editor-heading-container {
      padding: 15px;
      margin: 10px;
    }
  
    .editor-heading {
      font-size: 2em;
    }
  
    .editor-description {
      font-size: 1em;
    }
  }
  