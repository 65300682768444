.legal-container {
    max-width: 1200px; /* Reduced width for better readability */
    margin: 40px auto;
    padding: 30px;
    font-family: 'Arial', sans-serif;
    line-height: 1.7;
    color: black; /* Darker color for better readability */
    border-left: 2px solid black;
    margin-top: 30px;
    text-align: justify;
  }
  
  .legal-container h1 {
    font-size: 2.2em;
    margin-bottom: 20px;
    color: white; /* Consistent color for headings */
    background-color: black;
   text-align: center;
  }

  .legal-container h1:hover {
    background-color: black;
    text-decoration: none;
  }
  
  .legal-container h2 {
    font-size: 2em;
    margin-top: 30px;
    margin-bottom: 15px;
    color: black; /* Slightly lighter color for subheadings */
    text-decoration: underline;
  }

  .legal-container h3 {
    font-size: 1.6em;
    margin-top: 30px;
    margin-bottom: 15px;
    color: black; /* Slightly lighter color for subheadings */
  }
  
  .legal-container p {
    font-size: 1.4em;
    margin-bottom: 20px;
    color: black;
  }

  .legal-container p a{
    font-size: 1.4em;
    margin-bottom: 20px;
    color: white;
    background-color: black;
    padding: 10px;
  }

  .legal-container p a:hover{
    background-color: #0C831F;
  }
  
  .legal-container ul {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .legal-container ul li {
    font-size: 1.4em;
    margin-bottom: 10px;
    color: black;
  }
  
  .legal-contact {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 8px;
    font-size: 1.2em;
    color: black;
    margin-bottom: 50px;
  }
  
  .legal-contact p {
    margin: 0;
  }
  
  .legal-contact a {
    color: black;
    text-decoration: none;
  }
  
  .legal-contact a:hover {
    text-decoration: underline;
  }
  
  .contact-link {
    /* display: block; */
    margin-top: 15px;
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-link:hover {
    text-decoration: underline;
  }
  
  