.template-editor-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    /* background-color: #f8f9fa; */
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.fullscreen-btn {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #0C831F;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    font-size: 20px;
    transition: background-color 0.3s;
}

.fullscreen-btn:hover {
    background-color: #0056b3;
}

.desktop .fullscreen-btn {
    display: block;
}

.template-editor-left {
    flex: 1;
    max-width: 40%; /* 1/4th of the container width */
    height: 100vh;
    /* background-color: #f1f3f5; */
    border-radius: 8px;
    padding: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content vertically */
}

.template-editor-left h2 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #343a40;
}

.template-editor-left .template {
    width: 60%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s, transform 0.3s;
    border-radius: 5px;
    margin-bottom: 20px; /* Add space between templates */
}

.template-editor-left .template-container {
    display: flex;
    justify-content: center; /* Center the template images horizontally */
    width: 100%;
}


.template-editor-left .template.selected {
    border-color: #0C831F;
    transform: scale(1.05);
}

.template-editor-right {
    flex: 3;
    max-width: 60%; /* 3/4th of the container width */
    height: 100vh;
    /* background-color: #ffffff; */
    border-radius: 8px;
    padding: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

.template-editor-right .template-id {
    width: 100%;
    max-width: 600px; /* Align with the form's max width */
    padding: 10px;
    background-color: #f1f3f5;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 20px;
    text-align: center; /* Center the text */
    font-size: 14px; /* Adjust font size */
    color: #495057; /* Text color */
}

.template-editor-right form {
    width: 100%;
    max-width: 600px; /* Maximum width for better readability */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the form fields horizontally */
}

/* ...rest of your CSS */


.template-editor-right .form-group {
    width: 100%;
    margin-bottom: 20px; /* Add space between form groups */
}

.template-editor-right label {
    display: block;
    font-weight: 500;
    color: #495057;
    margin-bottom: 8px;
    font-size: 16px; /* Adjust font size for readability */
}

.template-editor-right input,
.template-editor-right textarea {
    width: 100%;
    padding: 12px;
    margin-top: 4px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #f8f9fa;
    /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); */
    transition: border-color 0.3s;
    font-size: 16px; /* Consistent font size */
}

.template-editor-right input::placeholder,
.template-editor-right textarea::placeholder {
    color: #6c757d; /* Placeholder color */
    /* font-style: italic; */
}

.template-editor-right input:focus,
.template-editor-right textarea:focus {
    border-color: #0C831F;
    background-color: #ffffff;
    outline: none;
}

.template-editor-right textarea {
    resize: vertical; /* Allow vertical resizing */
    min-height: 100px; /* Minimum height for textareas */
}

.submit-template-btn {
    margin-top: 10px;
    width: 98%;
    padding: 12px;
    border: none;
    border-radius: 6px;
    background: #0C831F;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
  }
  
  .submit-template-btn:hover {
    background: #0C831F;
    transform: scale(1.02);
  }


.mobile {
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.mobile .template-editor-left {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .mobile .template-editor-right {
    max-width: 100%;
  }
  

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
}

.ad-form-details-desc {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 1.1em; /* Slightly larger font size for better readability */
    line-height: 1.6; /* Improve readability with increased line height */
    color: #333; /* Dark grey color for better contrast and softer than black */
    max-width: 800px; /* Limit width to make text easier to read */
    margin-left: auto; /* Center the paragraph horizontally */
    margin-right: auto; /* Center the paragraph horizontally */
    padding: 10px 30px; /* Add padding for better spacing on small screens */
    font-family: 'Arial', sans-serif; /* Clean, modern font */
    border-left: 4px solid #0C831F; /* Add a subtle border for emphasis */
    padding-left: 15px; /* Add padding inside the border */
    background-color: #f9f9f9; /* Light background color for contrast */
    border-radius: 8px; /* Rounded corners for a modern look */
}



