.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.signup-form {
  background: #fff;
  padding: 50px 30px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 90%;
  max-width: 400px;
}

.svg-logo {
  display: block;
  margin: 0 auto 20px;
  width: 150px; /* Adjust the size of the logo */
  height: auto;
}

.signup-heading {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: black;
}

.signup-form-fields {
  display: flex;
  flex-direction: column;
}

.signup-input {
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
}

.signup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  border-top-right-radius: 0px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  margin-bottom: 20px;
  font-weight: 700;
  background-color: #0C831F;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.signup-button:hover {
  transform: scale(1.02);
}

.signin-link {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

.signin-link a {
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.signin-link a:hover {
  text-decoration: underline;
  color: white;
  background-color: black;
  padding: 5px 10px;
}
