.contact-container {
  font-family: Arial, sans-serif;
  color: black;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  margin-top: 2rem;
}

.contact-us-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
  align-items: center; 
}

.contact-us-header h1 {
  font-size: 2.5em;
  color: black;
  margin: 0;
  line-height: 1.6;
  margin-bottom: 0.8rem;
}

.contact-us-header p {
  width: 65%;
  align-self: center;
  line-height: 1.5;
  color: black;
  margin: 5px 0 0;
}

.contact-container-body {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  margin: auto;
}

.contact-form, .contact-info {
  flex: 1;
  min-width: 280px;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

/* .contact-form {
  background: #f9f9f9;
}

.contact-info {
  background: #fff;
} */

.contact-form h2, .contact-info h2 {
  /* margin-bottom: 10px; */
  font-size: 24px;
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 1.2em;
}

.contact-form .name-fields {
  display: flex;
  gap: 16px; /* Adjust spacing between fields if needed */
  margin-bottom: 16px;
  /* font-size: 1.2em; */
}

.contact-form .name-fields .field {
  flex: 1;
   /* font-size: 1.2em; */
}

.contact-form input, .contact-form textarea {
  padding: 12px; /* Increased padding for better touch targets */
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners for inputs */
  box-sizing: border-box; /* Ensures padding does not affect width */
  width: 100%;
  margin-bottom: 15px;
}

.contact-form textarea:hover{
  transform: scale(1.01);
}

.contact-form input:hover{
  transform: scale(1.01);
}

.contact-form button {
  background-color: rgba(12, 131, 31, 0.05);
  border: 1.5px solid #0C831F;
  border-radius: 5px;
  margin-top: auto; 
  color: #0C831F;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 3rem;
  transition: background-color 0.3s ease;
  width: 100%; 
  box-sizing: border-box; 
}

.contact-form button:hover {
  background-color: #0C831F;
  color: white;
  transform: scale(1.01); 
}


/* .contact-info h2 {
  margin-bottom: 20px;
} */

.contact-info p{
  margin-bottom: 10px;
  margin-top: 10px;
  color: black;
  line-height: 1.5;
}

.contact-methods {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-link {
  /* text-decoration: none; */
  color: inherit; /* Keeps the text color same as surrounding text */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.contact-phone{
  text-decoration: none;
  color: inherit; /* Keeps the text color same as surrounding text */
  display: flex;
  align-items: center;
}

.contact-phone p{
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.contact-link p {
  margin: 0;
  font-weight: bold; /* Make the text bold if preferred */
  text-decoration: underline;
  color: black;
}

.contact-link:hover .icon {
  color: black; /* Change icon color on hover */
}

.contact-method .icon {
  font-size: 24px;
  color: black;
  margin-right: 10px;
}

.social-media {
  margin-top: 20px;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons .icon {
  font-size: 24px;
  color: #333;
  transition: color 0.3s;
}

.social-icons .facebook:hover { color: #3b5998; }
.social-icons .instagram:hover { color: #e4405f; }
.social-icons .twitter:hover { color: #1da1f2; }

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  .contact-form, .contact-info{
    padding: 0px;
  }

  .contact-us-header p{
    /* font-size: 1rem; */
    width: 95%;
  }

  .contact-us-header h1{
    font-size: 36px;
    margin-bottom: 0.5rem;
  }

}
