/* CheckoutPage.css */

.checkout-page-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
  /* height: 100vh; */
  /* overflow: hidden; */
  background-color: #f9f9f9; /* Light background color */
}

.checkout-page-left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ddd;
  max-height: 100vh;
  padding-right: 10px;
  /* overflow-y: auto; */
}

.checkout-page-right-side {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  max-height: 100vh;
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 200px;
}

/* .checkout-page-right-side-heading{
  font-size: 22px;
} */

.checkout-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 90%; /* Adjusted width */
  background-color: #ffffff; /* White background for the form */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  border-radius: 8px; /* Rounded corners */
  margin-top: 20px;
  padding: 40px 60px; 
}

.checkout-form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Increased gap for better spacing */
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Adjust gap as needed for spacing */
}

.form-group label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px; /* Added margin to create space between label and input */
}

.form-group input {
  padding: 12px; /* Increased padding for better touch targets */
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners for inputs */
  box-sizing: border-box; /* Ensures padding does not affect width */
}

.form-group input:hover {
  transform: scale(1.01);
}

.form-label-icon{
  margin-right: 8px;
}

/* Button Styles */
.submit-button {
  background-color: rgba(12, 131, 31, 0.05);
  border: 1.8px solid #0C831F;
  border-radius: 5px;
  margin-top: auto; 
  color: #0C831F;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 3rem;
  transition: background-color 0.3s ease;
  width: 100%; 
  box-sizing: border-box; 
}

.submit-button:hover {
  background-color: #0C831F;
  color: white;
  transform: scale(1.01); /* Subtle scale effect on hover */
}

.submit-button:active {
  transform: scale(0.98); /* Subtle scale effect on active */
}

/* Location Group Styles */
.location-group {
  margin-bottom: 15px; /* Increased margin */
}

.location-group input {
  display: block;
  width: 100%;
  margin-bottom: 10px; /* Increased margin */
}

/* Add Location Button Styles */
.add-location-button {
  background-color: rgba(12, 131, 31, 0.05);
  border: 1.5px solid black;
  border-radius: 5px;
  margin-top: auto; 
  color: black;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 3rem;
  transition: background-color 0.3s ease;
  width: 60%; 
  box-sizing: border-box; 
}

.add-location-button:hover {
  /* background-color: black;
  color: white; */
  transform: scale(1.01);
}

.add-location-button:active {
  transform: scale(0.98); /* Subtle scale effect on active */
}

/* Divider Styles */
.my-divider {
  border-bottom: 1px solid #0C831F;
  margin: 20px 0; /* Increased margin */
  width: 90%;
  align-self: center;
}

/* Template Section */
/* .template-group {
  margin-bottom: 1rem; 
  padding: 0.2rem; 
} */

.template-label {
  display: flex;
  align-items: start; 
  font-size: 1rem; 
  font-weight: 600;
  color: #333; 
  margin-bottom: 0.5rem; 
}

.template-icon {
  margin-right: 0.5rem; 
  font-size: 1.2rem; 
}

.template-select {
  width: 100%; 
  padding: 1rem; 
  /* border: 1px solid #ced4da;  */
  border-radius: 4px; 
  background-color: #ffffff; 
  font-size: 1rem;
  /* color: #495057;  */
  outline: none; 
  transition: border-color 0.2s ease; 
}

.template-select:hover{
  transform: scale(1.01);
}


.template-select:focus {
  border-color: #0C831F; 
  /* box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);  */
}

.template-select option {
  padding: 1rem;
  font-size: 1rem; 
}



/* Payment Method Section */
.payment-method-group {
  margin-bottom: 1.5rem;
  padding: 0.5rem;
}

.payment-method-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.payment-method-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.payment-method-select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1rem;
  /* color: #495057; */
  outline: none;
  transition: border-color 0.2s ease;
}

.payment-method-select:hover{
  transform: scale(1.01);
}

.payment-method-select:focus {
  border-color: #0C831F; ;
  /* box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25); */
}

.payment-method-select option {
  padding: 0.75rem;
  font-size: 1rem;
}


/* Responsive Design */
@media (max-width: 768px) {
  .checkout-page-container {
    flex-direction: column;
    overflow: auto;
  }

  .checkout-page-left-side, .checkout-page-right-side {
    flex: none;
    max-height: none;
    overflow: visible;
    padding: 10px;
  }

  .checkout-form-container {
    width: 100%;
    padding: 15px; /* Adjusted padding for mobile */
  }

  .checkout-form {
    gap: 15px; /* Adjusted gap for mobile */
  }
}