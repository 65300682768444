/* General container for the layout */
.container {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  padding: 20px;
  margin: auto;
  margin-top: 2rem;
}

/* Left Side - SVG Image */
.left-side {
  flex: 0 0 30%;
  /* Adjust width as needed */
  /* position: sticky; */
  /* top: 0; */
  /* height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  /* Optional background color */
}

.bigdoon-background-black {
  display: inline-block; /* Ensures proper rotation and alignment */
  color: white;
  background-color: black;
  padding: 8px 12px;
  transform: rotate(-1deg); /* Rotates the element by 15 degrees */
  transform-origin: center; /* Keeps the rotation centered around the element */
}


/* Right Side - Careers Content */
.right-side {
  flex: 1;
  padding: 30px;
  /* overflow-y: scroll;
  max-height: 100vh; */
}

/* General Styles */
.careers {
  font-family: Arial, sans-serif;
  color: black;
  /* padding-bottom: 120px; */
}

.carrer-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
  align-items: center;
}

.carrer-header h1 {
  font-size: 2.5em;
  color: black;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 0.8rem;
}

.carrers-header p {
  font-size: 1.3em;
  color: black;
  margin-top: 10px;
}

.carrer-desc-container {
  width: 90%;
  margin: 10px auto;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.carrer-desc {
  font-size: 1.5em;
  color: black;
  line-height: 1.7;
  font-style: italic;
  /* border-left: 2px solid black; */
  text-align: center;
  /* padding: 20px; */
  margin: 0;
}

.carrer-desc-highlight {
  background-color: black;
  color: white;
  font-weight: bold;
  padding: 6px;
}

/* Ensure all content is scrollable */
.right-side>* {
  margin-bottom: 20px;
  /* Space between sections */
}

/* Job Listings */
.job-listings {
  margin-bottom: 60px;
  margin-top: 28px;
}

.job-listings h2 {
  font-size: 1.8em;
  color: black;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
}

.job-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  padding: 35px;
  margin-bottom: 30px;
  font-size: 1.2em;
  text-align: center;
  transition: box-shadow 0.3s ease;
}

/* .job-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
} */

.job-card h3 {
  font-size: 2em;
  color: black;
  margin-bottom: 15px;
  font-weight: 600;
}

.job-card p {
  font-size: 1.1em;
  color: black;
  margin: 10px 0;
}

#job-card-button {
  /* background-color: rgba(12, 131, 31, 0.05); */
  border: 1.5px solid #0C831F;
  border-radius: 1px;
  margin-top: 20px;
  color: #0C831F;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 3rem;
  transition: background-color 0.3s ease;
  width: 60%;
  box-sizing: border-box;
}

#job-card-button:hover {
  background-color: #0C831F;
  color: white;
  transform: scale(1.01);
}

/* Company Culture */
.company-culture {
  text-align: center;
  margin-bottom: 60px;
}

.company-culture h2 {
  font-size: 2.8em;
  /* color: white;
  background-color: black; */
  /* padding: 8px; */
  margin-bottom: 25px;
  font-weight: 600;
}

.company-culture p {
  font-size: 1.3em;
  color: black;
  line-height: 1.7;
  margin-bottom: 25px;
}

.company-culture ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-culture li {
  font-size: 1.3em;
  color: white;
  background-color: #0C831F;
  padding: 8px 48px;
  margin-bottom: 15px;
}

/* Application Instructions */
.application-instructions {
  text-align: center;
}

.application-instructions h2 {
  font-size: 2.8em;
  color: black;
  margin-bottom: 25px;
  font-weight: 600;
}

.application-instructions p {
  font-size: 1.3em;
  color: black;
  line-height: 1.5;
}

.application-instructions a {
  background-color: black;
  color: white;
  padding: 8px;
  text-decoration: none;
  font-weight: bold;
}

.application-instructions a:hover {
  text-decoration: underline;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  /* Darker overlay for better contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  border-radius: 1px;
  padding: 30px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 22px;
  line-height: 1;
}

.popup-close:hover {
  background: #c0392b;
}

.popup-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-option {
  display: block;
  margin: 12px 0;
  font-size: 1.3em;
  color: #0C831F;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

/* .popup-option:hover {
  color: #2980b9;
} */

.pagination-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button{
  color: white;
  background-color: black;
  padding: 10px 15px;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.pagination-button:hover{
  transform: 1.2;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  color: black;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0C831F;
}

/* Additional styles for the first and last page buttons */
.pagination-button-first {
  background-color: #28a745; /* Green for first page */
}

.pagination-button-last {
  background-color: #dc3545; /* Red for last page */
}


/* Responsive Styles */
@media (max-width: 1024px) {
  .left-side {
    flex: 0 0 30%;
  }

  .careers-header h1 {
    font-size: 2.2em;
  }

  .careers-header p {
    font-size: 1.2em;
  }

  .job-card {
    padding: 20px;
  }

  .job-card h3 {
    font-size: 1.8em;
  }

  .job-card p {
    font-size: 1em;
  }

  .job-card button {
    font-size: 1em;
  }

  .company-culture h2,
  .application-instructions h2 {
    font-size: 1.8em;
  }

  /* .company-culture p,
  .application-instructions p {
    font-size: 1em;
  } */
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-side {
    width: 100%;
    height: auto;
    position: relative;
  }

  .right-side {
    padding: 2px;
    overflow-y: hidden;
    max-height: 100%;
  }

  .carrer-header h1 {
    margin-top: 20px;
  }



  .careers-header p {
    font-size: 1em;
  }

  .job-card {
    padding: 15px;
  }

  .job-card h3 {
    font-size: 1.6em;
  }

  .job-card p {
    font-size: 0.9em;
  }

  .job-card button {
    font-size: 0.9em;
  }

  .company-culture h2,
  .application-instructions h2 {
    font-size: 1.8em;
  }

  /* .company-culture p,
  .application-instructions p {
    font-size: 0.9em;
  } */

}

@media (max-width: 480px) {
  .careers-header h1 {
    font-size: 1.8em;
  }

  .careers-header p {
    font-size: 0.9em;
  }

  .job-card {
    padding: 10px;
  }

  .job-card h3 {
    font-size: 1.4em;
  }

  .job-card p {
    font-size: 0.8em;
  }

  .job-card button {
    font-size: 0.8em;
  }

  .company-culture h2,
  .application-instructions h2 {
    font-size: 1.8em;
  }

  /* .company-culture p,
  .application-instructions p {
    font-size: 0.8em;
  } */
}