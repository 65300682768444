.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.forgot-password-form {
  background: #fff;
  padding: 50px 30px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 90%;
  max-width: 400px;
}

.svg-logo {
  display: block;
  margin: 0 auto 20px;
  width: 150px;
  /* Adjust the size of the logo */
  height: auto;
}


.forgot-password-form h1 {
  margin-bottom: 12px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.forgot-password-form p {
  margin-bottom: 30px;
  font-size: 16px;
  text-align: center;
  color: #333;
}

.forgot-password-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 1.5rem;
}

.forgot-password-form button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  border-top-right-radius: 0px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  margin-bottom: 20px;
  font-weight: 700;
  background-color: #0C831F;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.forgot-password-form button:hover {
  transform: scale(1.02);
}

.forgot-password-form button:disabled {
  background-color: black;
}

.back-to-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  margin-top: 28px; 
}

.back-icon {
  font-size: 22px; 
  margin-right: 8px; 
  color: black; 
}

.back-to-login span {
  color: black; 
}

.back-to-login:hover span {
  text-decoration: underline;
}